.donate-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  position: relative;
  
}
  .donate-image {
    width: 500px; 
    height: auto;
  }
  .first-section-don {
    background-image: url("https://img.freepik.com/premium-vector/word-cloud-background-concept-donation-charity-support-finance-contribution-help-community-fundraising-vector-illustration_616200-3301.jpg");
  }
  .image-section-don {
    height: 35vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: end;
    color: white;
    font-size: 2rem;
   padding-bottom: 20px;
   background-color: rgba(0, 0, 0, 0.5);
   border-bottom-left-radius: 50px;
   border-bottom-right-radius: 50px;

  }
  .carousel-overlay-don {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:35vh;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  @media screen and (max-width: 768px) {
    .donate-image {
        width: 400px;
        height: 500px;
    }
    .image-section-don {
      height: 20vh;
      background-size: cover;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: end;
      color: white;
      font-size: 2rem;
     padding-bottom: 20px;
     background-color: rgba(0, 0, 0, 0.5);
     border-bottom-left-radius: 50px;
     border-bottom-right-radius: 50px;
  
    }
    .carousel-overlay-don {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height:20vh;
      background-color: rgba(0, 0, 0, 0.5); 
      z-index: 1; 
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
    }

  }
  .razorpay-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120vh;
    border-radius: 20px;
  }
  
  .razorpay-left-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3399cc;
  }
  
  .razorpay-logo {
    max-width: 80%;
    height: auto;
  }
  
  .razorpay-right-section {
    flex: 1;
    padding: 40px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .razorpay-right-section h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .razorpay-form-group {
    margin-bottom: 20px;
  }
  
  .razorpay-form-group label {
    display: block;
    margin-bottom: 8px;
    color: #666;
  }
  
  .razorpay-form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button {
    display: inline-block;
    background-color: #3399cc;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .button:hover {
    background-color: #287a99;
  }
  