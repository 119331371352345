.grid-container-gly {
    display: grid;
    grid-template-columns: auto auto auto  ;
    grid-gap: 30px; 
    padding: 30px; 
    margin-top: 50px;
    margin-bottom: 100px;
  }
  
  .grid-item-gly img {
    width: 90%;
    height: 100%;
    border-radius: 20px;
  }
  .first-section-gly {
    background-image: url("https://images.pexels.com/photos/297494/pexels-photo-297494.jpeg?auto=compress&cs=tinysrgb&w=600");
  }
  .image-section-gly {
    height: 65vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: end;
    color: white;
    font-size: 2rem;
   padding-bottom: 20px;
   background-color: rgba(0, 0, 0, 0.5);
   border-bottom-left-radius: 50px;
   border-bottom-right-radius: 50px;

  }
  @media screen and (max-width: 768px) {
    .grid-container-gly {
        display: grid;
        grid-template-columns: auto   ;
        grid-gap: 30px; 
        padding: 20px; 
        margin-top: 50px;
        margin-bottom: 100px;
      }
      .left-content1 img{
        width: 300px;
      }
      .car{
        padding: 20px;
        margin:3px;
      }
      .card {
        cursor: pointer;
        margin: 0PX;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add box shadow */
    
      }
      .card .h-100{
        width: 350px;
        
      }
      .grid-item-gly img {
        width: 100%;
        height: 110%;
        border-radius: 20px;
      }
      .image-section-gly {
        height: 25vh;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: end;
        color: white;
        font-size: 2rem;
       padding-bottom: 20px;
       background-color: rgba(0, 0, 0, 0.5);
       border-bottom-left-radius: 50px;
       border-bottom-right-radius: 50px;
      }
     
  }
  .card {
    cursor: pointer;
    margin: 10PX;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add box shadow */

  }
  
  .crd {
    background-color: rgb(7, 7, 99);
    width: fit-content;
    height: fit-content;
    padding: 20px;
    border-radius: 20px;
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add box shadow */
  }
  .card img{
    height: 350px;
  }
  .car{
    padding: 20px;
    margin: 5px;
  }
  .card-title{
    color: blue;
    margin-bottom: 3cqb;
  }
  .col{
    .col {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a shadow around the column */
      }
  }
  .card {
    overflow: hidden;
    border-radius: 20px;
    cursor: pointer;
    transition: transform 0.3s;  }
  .card:hover {
    transform: scale(1.05);

    box-shadow: 0 8px 16px rgba(73, 30, 30, 0.3); /* Enhances the shadow on hover for better focus */
  }
  