.first-section-rat {
    background-image: url("https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/50.jpg");
  }
  .image-section-rat {
    height: 65vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: end;
    color: white;
    font-size: 2rem;
   padding-bottom: 20px;
   background-color: rgba(0, 0, 0, 0.5);
   border-bottom-left-radius: 50px;
   border-bottom-right-radius: 50px;

  }
  @import url('https://fonts.googleapis.com/css?family=Raleway');
  .container1 {
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 20px;
 width: fit-content;
    margin: 20px ;
    color: black;
    height: fit-content;
    transition: height 0.5s ease, opacity 0.5s ease;

  }
  

  .content1 p {
    margin-bottom: 20px;
  }
  
  #text1 {
    display: none;
  }
  
  .btn-container {
    margin: auto;
    height: 44px;
    width: 166.23px;
    position: relative;
    transition: max-height 0.5s ease, opacity 0.5s ease;
  }
  
  .btn-container button {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    border: none;
    padding: 8px;
    font-size: 20px;
    background: linear-gradient(141deg, #0fb8ad 0%, #1fc8db 51%, #2cb5e8 75%);
    color: white;
    font-family: cursive;
    box-sizing: border-box;
    width: 100%;
    border-radius: 5px;
  }
  
  .btn-container button:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }