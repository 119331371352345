.slideshow-container {
    display: grid;
    grid-template-columns: 40% 50%;
    position: relative;
    max-width: 1100px;
    height: 400px;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.2);
  }
  
  .slide {
    position: relative;
    width: 100%;
    height: 100%;

  }
  
  .slide img {
    width: 80%;
    height: 80%;
    object-fit: cover;
  }
  .slide .image-container1 {
    width: 80%;
    height: 80%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
  }
  
  .slide .image-container1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .caption {
    background-color: white;
    color: black;
    padding: 5px;
    font-size: 20px;
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    .slideshow-container {
      display: flex;
      flex-direction: column;
      height: 700px;
    }
  
    .slide {
      height: auto;
    }
  
    .slide .image-container {
      height: auto;
    }
  
    .caption {
      margin-top: 20px;
    }
  }
  .deposit-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: green;
    border: 2px solid white;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .deposit-button:hover {
    background-color: darkgreen;
  }
  
  .prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    padding: 12px;
    color: white;
    font-weight: bold;
    font-size: 24px;
    background-color: rgba(176, 168, 168, 0.6);
    cursor: pointer;
    text-decoration: none;
  }
  
  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
  }
  
  .prev {
    left: 0;
    border-radius: 0 3px 3px 0;
  }
  
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  