.first-section-chi {
    background-image: url("https://t3.ftcdn.net/jpg/07/19/14/36/240_F_719143640_VJUGtnSxlLkJmjqEGQvuOn1IUffDPevU.jpg");
  }
  .first-section-cancer {
    background-image: url("https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/5.jpg");
  }
  .image-section-chi {
    height: 65vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: end;
    color: white;
    font-size: 2rem;
   padding-bottom: 20px;
   background-color: rgba(0, 0, 0, 0.5);
   border-bottom-left-radius: 50px;
   border-bottom-right-radius: 50px;

  }
  .chi {
    width: fit-content;
    height: fit-content;
    margin: 20px;
    padding: 20px;
    border-radius: 30px;
    background-image: url('https://images.pexels.com/photos/10435674/pexels-photo-10435674.jpeg?auto=compress&cs=tinysrgb&w=600');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 

  }
  
  .right-content-chi img {
    width: 400px;
    height: fit-content;
    margin: 20px;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 

   
  }

  .right-content-chi img {
    width: 550px;
    height: auto;
  }
  .overlay5 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.848); 
    border-radius: 30px;
  }
  
  .text {
    position: relative;
    z-index: 1;
  }
  
  
  .chi p {
    color: black; 
    font-size: 18px;
    line-height: 1.5;
  }
  @media screen and (max-width: 768px) {
    .right-content-chi img {
      width: 300px;
      ; 
  
     
    }
    .left-content, .right-content {
      padding: 10px;
      transition: transform 0.3s;
    }
    
    .left-content {
      background-color:white;
     margin-left: 10px;
    }
  
    .image-section-chi{
      height: 35vh;
    }
    .image-section-abt{

    }
    .carousel-overlay-img{
      height: inherit;
    }
  }