.first-section-env {
    background-image: url("https://images.pexels.com/photos/957024/forest-trees-perspective-bright-957024.jpeg?auto=compress&cs=tinysrgb&w=600");
  }
  .image-section-env {
    height: 65vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: end;
    color: white;
    font-size: 2rem;
   padding-bottom: 20px;
   background-color: rgba(0, 0, 0, 0.5);
   border-bottom-left-radius: 50px;
   border-bottom-right-radius: 50px;

  }
  .env {
    width: fit-content;
    height: fit-content;
    margin: 20px;
    padding: 20px;
    border-radius: 30px;
    background-image: url('https://images.pexels.com/photos/1151418/pexels-photo-1151418.jpeg?auto=compress&cs=tinysrgb&w=600');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; /* Ensure position context for absolute positioning */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 

  }
  
  .env p {
    color: black; /* Text color */
    font-size: 18px;
    line-height: 1.5;
  }
  .content-container {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .content-container.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  .content-container1{
    padding: 60px 20px;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    min-height: 60vh;
    border-radius: 50px;
  }
  @media (max-width: 768px) {
    .flex-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .donate {
      margin-left: 35px;
      margin-bottom: 40px;
      width:fit-content;
    }
  
    .vertical-line {
      width: 100%;
      height: 1px;
      margin-bottom: 10px;
    }
  
    .text-container {
      padding-left: 0;
      max-width: 100%;
    }
  }