/* ImageGridComponent.css */
.main-container {
  display: flex;
  height: 100vh;
}

.background-container-dash {
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  height: 100vh;
  background-image: url('https://images.hdqwalls.com/wallpapers/early-morning-mountains-scenery-8n.jpg');
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.content-container-dash {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateX(-100%);
  opacity: 0;
  margin-top: 60px;
  transition: transform 1s ease, opacity 1s ease;
}

.content-container-dash.loaded {
  transform: translateX(0);
  opacity: 1;
}

.content-title-dash {
  font-size: 60px;
  color: white;
  margin-bottom: 1rem;
}

.content-text-dash {
  font-size: 60px;
  color: rgb(255, 102, 0);
  margin-bottom: 0.5rem;
}

.tx {
  color: white;
}





.image-grid-dash {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-left: 50px;
  margin-top: 150px;
}

.image-item-dash {
  position: relative;
}

.image-item-dash img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  max-height: calc(100vh / 2 - 1rem); /* Adjust this value for random heights */
}


.tx{
  color: white;
}
.text-box {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: right;
}

.text-box p {
  margin: 0;
}

.content-container {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.content-container.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.carousel-quote-container {
  position: absolute;
  bottom: 250px;
  left: 35%;
  transform: translateX(-50%);
  z-index: 2;
  width: 50%;
}

.carousel-quote {
  font-size: 40px;
  font-weight: bold;
  background: linear-gradient(90deg, rgba(255,0,150,1) 0%, rgba(0,204,255,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  transform: translateX(100%);
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.donate-now-mobile {
  font-size: 18px;
  color: white;
  background-color: #ff4081;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  opacity: 0;
  transform: translateX(100%);
  transition: transform 1s ease-in-out 0.5s, opacity 1s ease-in-out 0.5s;
  border-radius: 20px;
}
.donate-now-mobile::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top left, rgba(255,0,150,1), rgba(0,204,255,1));
  transform: scaleX(0);
  transform-origin: center left right;
  transition: transform 0.5s ease-in-out;
  z-index: -1;
  border-radius: 20px;

}

.donate-now-mobile:hover::before {
  transform: scaleX(1);
  transform-origin: center left right;
  border-radius: 20px;

}

.carousel-item.active .carousel-quote {
  opacity: 1;
  transform: translateX(0);
}

.carousel-item.active .donate-now-mobile {
  opacity: 1;
  transform: translateX(0);
}
  @media screen and (max-width: 768px) {
    .carousel-image {
      height: 100vh; 
    }
    .carousel-wrapper {
        display: flex;
        width: 1200%; 
        animation: slideShow 20s infinite  ease-in-out;
      }
      .image-grid-dash{
        display: none;
      }
      .carousel-quote {
        bottom: 50px; 
        right: 20px; 
        width: 100%;
        padding: 10px;
        font-size: 30px;
      }
      .carousel-item{
        height: 100vh;
      }
      .donate-now-mobile {
        display: block;
        position: absolute;
        top: 90%;
        left: 10%;
        transform: translate(-50%, -50%);
        z-index: 2; 
        background-color: white; 
        padding: 5px 5px;
        border: none;
        border-radius: 30px;
        color: black; 
        font-weight: bold;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        text-align: right;
        font-size: 20px;
        width: 90%;
      }
      
  }


  .slideshow-title {
    text-align: center;
    margin: 0;
    font-size: 50px; 
    margin-bottom: 50px;
    font-weight: bold; 
    color: #b03434; 
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
  }

  .image-gallery-container {
    display: flex;
    justify-content: center;
    animation: slideUp 0.8s forwards;
  }
 
  
  .visible {
    animation: slideUp 1s forwards;
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .image-item {
    display: inline-block;
    text-align: center;
    margin: 30px;
  }
.im{
  width: 100px;
  height: 70px;
}
  .image-overlay-container {
    position: relative;
    display: inline-block;
    width: 140px;
    height: 110px;
    border-radius: 50%;
    background-color: white;
    overflow: hidden;
    transition: transform 0.5s;
  }
  .image-overlay-container:hover{
background-color: azure;
  }
  .image-overlay-container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 200%;
    max-height: 100%;
  }
.linear{
  color:rgb(172, 79, 95);
}
  .image-overlay-container img.overlay {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  
  @media (max-width: 768px) {
    .image-gallery {
      grid-template-columns: auto auto;
      /* Display one column on smaller screens */
    }
    .semi {
 background-color: none;
    }
    .line-our {
    display: none;
    }

   p .donate1 {
        width:122px;
        height: 50px;
        background-color: white;
      border-radius: 30px;
        color: black;
        padding-bottom: 5px;
        margin-left: 40px;
       display:block;
 z-index: 5;
      }
      .content-containervol{
   
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 150px;
        margin-top: 100px;
        border-radius: 50px;
        margin-bottom: 50px;
       
      }
      .content-containervol p{
        
        text-align: center;
        font-size: 30px;
      }
      .content-containervol p, .content-containervol center {
       
        z-index: 2; 
        margin: 10px 0; 
      }
      
  }
  
  @keyframes slideShow {
    0% {
      transform: translateX(0);
    }
    33% {
      transform: translateX(-33.33%);
    }
    66% {
      transform: translateX(-66.66%);
    }
    100% {
      transform: translateX(0);
    }
  }
  .content-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    animation: slideUp 0.8s forwards;
  }
  
  .left-content, .right-content {
    padding: 30px;
    transition: transform 0.3s;
  }
  
  .left-content {
    background-color:white;
   margin-left: 20px;
  }
  .tex{
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    font-size: 20px;
    background: linear-gradient(90deg, rgba(255,0,150,1) 0%, rgba(0,204,255,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .right-content img {
    width: 100%;
    height: auto;
  }
  .main {
    /* Display and positioning */
    display: inline-block;
    position: relative;
  
    /* Text gradient */
    background: linear-gradient(90deg, rgb(255, 0, 0) 0%, rgb(0, 255, 162) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent; /* for browsers that support background-clip: text without prefixes */
  
    /* Animation */
    animation: float 3s ease-in-out infinite;
  }
  
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  .image-item .image-overlay-container {
    position: relative;
  }
  .right-content1 img {
    width: 100%;
    height: auto;
  }
  .image-item .overlay {
    position: absolute;
    top: 18%;  
    left: 24%; 
    width: 50%;  
    height: 50%; 
    opacity: 0.7; 
    display: flex;
    justify-content: center;
    background-color: transparent;
    align-items: center;
  }
  .slide {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    font-size: 20px;
    transition: transform 0.5s ease-in-out;
    position: relative; 
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; 
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
  }
  .our{
    font-size: 60px;
    color: rgb(184, 86, 11);
    font-family: Calibri;
  }
  .our:hover{
color: red;
  }
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .about-us-container {
    text-align: center;
    padding: 20px;
  }
  
  .main-vision-title {
    font-family:  Calibri;
    font-size: 40px;
    margin-bottom: 50px;
    color: green;
  }
  
  .main-vision-description {
    font-family: Calibri;
    font-size: 25px;
  }
  
  .image-gallery-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .image-item {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .image-overlay-container {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
  }
  
  .overlay {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .image-item span {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  
  .image-item:hover .image-overlay-container {
    transform: scale(1.1);
  }
  
  .image-item:hover::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: background 0.5s ease-in-out;
  }
  
  .slide.active {
    opacity: 1;
  }
  
  .slide img {
    width: 100%;
    height: 90%;
    object-fit: cover;
  }
  
  .quote {
    position: absolute;
   margin-bottom: 200px;
    right: 50px; 
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    font-style: italic;
  }
  .image-container {
    position: relative; 
  }
  
  .image-container img {
    display: block; 
    width: 100%; 
    height: auto; 
  }
  
  .image-text {
    position: absolute; 
    bottom: 10px; 
    right: 10px; 
    background-color: rgba(0, 0, 0, 0.5); 
    color: white;
    padding: 5px 10px; 
    font-size: 16px; 
  }

  .imagesrc{
   
    height: 200px;
  }
  .test{
    width: 100%;
    height: fit-content;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-top: 50px;
  }
  .content-containertest{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    animation: slideUp 0.8s forwards;
    border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-top: 100px;
  }
  .content-container-vol{
   
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
padding: 20px;
    margin-top: 100px;
    border-radius: 50px;
    margin-bottom: 50px;
    padding-top: 100px;
    padding-bottom: 50px;
    font-size: 20px;
   
  }
  .content-containervol p{
    text-align: center;
  
    font-size: 30px;
  }
  .content-containervol p, .content-containervol center {
    position: relative;
    z-index: 2; 
    margin: 10px 0; 
  }
  
  .flex-container {
    display: flex;
    align-items: center; /* Aligns children vertically */
    justify-content: space-evenly; /* Spreads the flex children */
    width: 100%; /* Full width of the container */
  }
  
  .donate_button1 {
    margin-right: 20px; /* Optional: adjust spacing between button and line */
  }
  
  .vertical-line {
    width: 1px;
    background-color: #ccc;
    height: 150px; /* Adjust based on your content */
  }
  
  .text-container {
    padding-left: 20px; /* Optional: adjust spacing between line and text */
    max-width: 50%; /* Limits the text width for better readability */
  }
  .donate_button1{
    background-color: #7f90a2; 
    color: white; 
    padding: 20px 30px; 
    border: none;
    border-radius: 20px; 
    cursor: pointer; 
    font-size: 20px;
    border-width: 2px;
    border-style: solid;
    font-weight: bold;
    
  }
  
.donate_button1:hover{
  background-color: #accaeb; 
  color: rgb(0, 0, 0); 
  padding: 20px 30px; 
  border: none;
  border-radius: 20px; 
  cursor: pointer; 
  font-size: 20px;
  border-width: 2px;
  border-style: solid;
  font-weight: bold;
}
.about{
    border-radius: 50px;
}

/* bigshot one font */
@import url(https://fonts.googleapis.com/css?family=Cabin+Condensed);


svg{font-weight:bold;max-width:300px}