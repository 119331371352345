/* Image section styles */
.image-section {
    height: 65vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: end;
    color: white;
    font-size: 2rem;
   padding-bottom: 20px;
   background-color: rgba(0, 0, 0, 0.5);
   border-bottom-left-radius: 50px;
   border-bottom-right-radius: 50px;
  }
  .image-section1 {
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2rem;
    margin-bottom: 100px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  
  }
  .carousel-overlay-img h1{
    z-index: 4;
  }
  .carousel-overlay-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:65vh;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .first-section {
    background-image: url("https://images.pexels.com/photos/5029853/pexels-photo-5029853.jpeg?auto=compress&cs=tinysrgb&w=600");
  }
  
  .second-section {
    background-image: url("https://images.pexels.com/photos/9324330/pexels-photo-9324330.jpeg?auto=compress&cs=tinysrgb&w=600");
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
   border-top-right-radius: 50px;
   height: 150vh;
}
  
  
  .form-container {
   
    display: grid;
    grid-template-columns: auto auto;

 
    padding: 30px;
   
   
    width: 700px; 
  }
  @media screen and (max-width: 768px) {
    .form-button {
        width: 0%; 
    
        cursor: pointer;
        color: white;
        border: none;
        border-radius: 10px;
        font-size: 25px;
      }
    .form-container {
   
        display: grid;
        grid-template-columns: auto ;
    
     
        padding: 30px;
       
     
        width: fit-content; 
      }
      .form{
        background-color: rgba(156, 151, 151, 0.5); 
        padding: 10px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
      }
      .form-input1 {
        width: calc(100% - 10px); 
        padding: 35px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 10px;
        font-size: 16px;
        background-color: rgb(230, 227, 227); 
      }

  }
  .form1{
    background-color: rgba(156, 151, 151, 0.5); 
    padding: 30px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  .form-input1 {
    width: calc(100% - 30px); 
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 16px;
    background-color: rgb(230, 227, 227); 
  }
  
  .form-button {
    width: 40%; 

    cursor: pointer;
    background-color: #0066ff;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 25px;
  }
