.first-section-con {
    background-image: url("https://images.pexels.com/photos/1416530/pexels-photo-1416530.jpeg?auto=compress&cs=tinysrgb&w=600");
  }
  .image-section-con {
    height: 65vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: end;
    color: white;
    font-size: 2rem;
   padding-bottom: 20px;
   background-color: rgba(0, 0, 0, 0.5);
   border-bottom-left-radius: 50px;
   border-bottom-right-radius: 50px;

  }
 
.right-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .grid-container-con {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
  }
  
  .grid-item-con {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .grid-image-con {
    width: 100px; 
    height: fit-content;
  }
  
  .box {
    width: 300px;

    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
  }
  .map iframe{
    width: 600px;
    height: 425px;
    margin-top: 30px;
  }
  @media screen and (max-width: 768px) {
    .map iframe{
        width: 300px;
        height: 425px;
        margin-top: 30px;
        margin-left: 30px;

      }
      .msg{
        display: grid;
        grid-template-columns: auto;
      }
      .in{
        flex: -1;
        margin-right: '10px';
         height:'40px';
         border-radius:'5px';
         
         width: 47%;
      }
      .image-section-con {
        height: 25vh;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: end;  
        color: white;
        font-size: 2rem;
       padding-bottom: 20px;
       background-color: rgba(0, 0, 0, 0.5);
       border-bottom-left-radius: 50px;
       border-bottom-right-radius: 50px;
    
      }
  }

  .in{
    flex: '1';
    margin-right: '10px';
     height:'40px';
     border-radius:'5px';
    
     width: 47%;
     margin-bottom : '30px'
  }
  .msg{
    display: 'flex';
     justify-content: 'space-between';
      margin-bottom : '30px'
  }
  .thought {
    width: 300px;
    height: 40px;
    background-color:white;
   
    position: fixed; /* Changed to fixed for smoother animation and constant positioning */
    top: 0; /* Start from top 0 */
    left: 50%;
    border-radius: 5px 5px;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
   text-align: center;
    font-size: 18px;
    font-weight: bold;
    animation: slideDown 0.5s forwards; /* Animation for sliding down */
    z-index: 2000;
    box-shadow: 0px 0px 10px rgb(233, 116, 116); 

}

@keyframes slideDown {
    0% { top: 0; }
    100% { top: 20%; }
}

.progress-line {
    position: absolute;
    bottom: -1px; /* Positioned at the bottom of the .thought */
    left: 0;
    height: 4px;
    background-color: rgb(70, 146, 15);
    border-radius: 5px 5px;

    width: 0; /* Start width from 0 */
    animation: loadProgress 5s forwards; /* Takes 5 seconds to complete */
}

@keyframes loadProgress {
    from { width: 100%; }
    to { width: 0%; }
}
.loading-spinner {
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top-color: white; 
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  