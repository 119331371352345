.footer {
    background-color: #f2f2f2;
    padding: 20px 0;
    font-size: 18px;
    text-decoration: none;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .footer-container {
    width: 90%; 
    margin: 0 auto;
    text-decoration: none;
  }
  
  .footer-grid {
    display: grid;
    grid-template-columns: auto auto auto auto; 
    gap: 20px; 
    height: fit-content;
    padding: 10px;
    text-decoration: none;
  }
  

  @media only screen and (max-width: 768px) {
    .footer-grid {
      grid-template-columns: auto  ; 
    }
  }
  
  .footer-item {
    padding: 10px;
  }
  
  .footer-item h2 {
    margin-bottom: 10px;
    font-size: 1.2em;
  }
  
  .footer-item p {
    line-height: 1.5; 
  }
  
  .footer-item ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-item li {
    margin-bottom: 5px;
    cursor: pointer;
  }
  
  .footer-item li:hover {
    margin-bottom: 5px;
    color: rgb(74, 74, 239);
  }
  .footer-item a {
    color: #333;
    text-decoration: none;
  }
  
 
  
  .copyright {
    text-align: center;
    color: #888;
    margin-top: 20px;
  }