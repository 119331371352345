.first-section-abt {
    background-image: url("https://wallpapers.com/images/hd/red-ultra-wide-hd-b4a87d5c76zmd946.jpg");
  }
  .image-section-abt {
    height: 65vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: end;
    color: white;
    font-size: 2rem;
   padding-bottom: 20px;
   background-color: rgba(0, 0, 0, 0.5);
   border-bottom-left-radius: 50px;
   border-bottom-right-radius: 50px;

  }
  .right-content-abt {
    width: 550px;
    height: 408px;
    background-size: cover;
    background-position: center;
    animation: changeBackground 16s infinite;
  border-radius: 20px;

}

@keyframes changeBackground {
    0% {
        background-image: url('https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/1.jpg');
    }
    25% {
        background-image: url('https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/2.jpg');
    }
    50% {
        background-image: url('https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/15.jpg');
    }
    75% {
        background-image: url('https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/27.jpg');
    }
    100% {
        background-image: url('https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/12.jpg');
    }
}
  @media screen and (max-width: 768px) {
    .right-content-abt  {
        width: 90%;
        height: auto;
      
      }
     
  }

  .right-content-chi:hover {
    animation-play-state: paused;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.core-values-container {
  text-align: center;
  padding: 20px;
}

.core-values-header {
  position: relative;
  margin-bottom: 30px;
}

.semi-circle {
  background-color: #f15a29;
  height: 200px;
  width: 100%;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  position: relative;
}
.semi-circle-our {
  background-color: #46b9b5;
  height: fit-content;
  width: 100%;
 border-radius: 100px;
  position: relative;
  z-index: 2;
  padding: 50px;
  
}
.semi-circle h2 {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  margin: 0;
  padding: 20px;
  
}



.core-values-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
}

.grid-item-awd {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 220px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  animation: shake 3s infinite;
}
.grid-item-our {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 20px;
  width: 130px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  animation: shake1 3s infinite;
  height: 150px;
  margin: 20px;
}
.grid-item-awd img {
  width: 40px;
  height: 40px;
}
.grid-item-our img {
  width: 50px;
  height: 50px;
}
.ou {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 20px;
  width: 130px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  animation: shake1 3s infinite;
  height: 150px;
  margin: 20px;
}
.grid-item-awd img {
  width: 40px;
  height: 40px;
}
.grid-item-our img {
  width: 50px;
  height: 50px;
}
.grid-item-awd .title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.grid-item-awd .description {
  font-size: 14px;
  color: black;

}

/* Gradient Text Animation */
.text {
  background: linear-gradient(90deg, rgb(149, 38, 102) 0%, rgb(38, 170, 203) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
  font-weight: bold;
  animation: gradientAnimation 5s infinite;
  transition: all 3s ease-in;
}



@keyframes shake {
  0%, 100% {
    transform: translateX(-3%);
  }
  50% {
    transform: translateX(3%);
  }
}
.ou {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 20px;
  width: 130px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  animation: shake2 3s infinite;
  height: 150px;
  margin: 20px;
}
@keyframes shake1 {
  0%, 100% {
    transform: translateY(-3%);
  }
  50% {
    transform: translateY(3%);
  }
}
@keyframes shake2 {
  0%, 100% {
    transform: translateY(3%);
  }
  50% {
    transform: translateY(-3%);
  }
}
.grid-item-awd::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  background-color: rgba(255, 185, 34, 0.325);
  z-index: 0;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  border-top-left-radius: 90%;

}

.grid-item-awd:hover::before {
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
}

.grid-item-awd:hover {
  transform: scale(1.05);
}
.line {
  width: 2px;
  background-color: #f15a29;
  position: absolute;
  top: -150px;
  left: 50%;
  height: 150px;
  z-index: -100;
}
.line-our {
  width: 2px;
  background-color: #7dbfbd89;
  position: absolute;
  top: -150px;
  left: 50%;
  height: 150px;
  z-index: -100;
}
@media screen and (max-width: 768px) {
  .right-content-abt img {
    width: 300px;
    ; 

   
  }
  .left-content, .right-content {
    padding: 10px;
    transition: transform 0.3s;
  }
 
  .left-content {
    background-color:white;
   margin-left: 10px;
  }

  .image-section-abt{
    height: 35vh;
  }
 
  .carousel-overlay-img{
    height: inherit;
  }
  .right-content-abt{
    height: 400px;
  }
  .line{
    display: none;
  }
}
a, a:link, a:visited {
  text-decoration: none;
  padding: 9px 15px;
  border: 1px solid #ececec;
  border-radius: 5px;
  color: gray;
}
*{
  font-family:  Calibri;
}
.animate {
  font-size: 50px;
  margin: 100px 0 0;
  z-index: 2;
  font-family: cursive;
}





.animate span:nth-of-type(2) {
  animation-delay: .05s;
}
.animate span:nth-of-type(3) {
  animation-delay: .1s;
}
.animate span:nth-of-type(4) {
  animation-delay: .15s;
}
.animate span:nth-of-type(5) {
  animation-delay: .2s;
}
.animate span:nth-of-type(6) {
  animation-delay: .25s;
}
.animate span:nth-of-type(7) {
  animation-delay: .3s;
}
.animate span:nth-of-type(8) {
  animation-delay: .35s;
}
.animate span:nth-of-type(9) {
  animation-delay: .4s;
}
.animate span:nth-of-type(10) {
  animation-delay: .45s;
}
.animate span:nth-of-type(11) {
  animation-delay: .5s;
}
.animate span:nth-of-type(12) {
  animation-delay: .55s;
}
.animate span:nth-of-type(13) {
  animation-delay: .6s;
}
.animate span:nth-of-type(14) {
  animation-delay: .65s;
}
.animate span:nth-of-type(15) {
  animation-delay: .7s;
}
.animate span:nth-of-type(16) {
  animation-delay: .75s;
}
.animate span:nth-of-type(17) {
  animation-delay: .8s;
}
.animate span:nth-of-type(18) {
  animation-delay: .85s;
}
.animate span:nth-of-type(19) {
  animation-delay: .9s;
}
.animate span:nth-of-type(20) {
  animation-delay: .95s;
}

/* Animation One */
.one span {
  color: yellow;
  opacity: 0;
  transform: translate(-150px, -50px) rotate(-180deg) scale(3);
  animation: revolveScale .4s forwards;
  z-index: 3;
  text-decoration: none;
}

@keyframes revolveScale {
  60% {
    transform: translate(20px, 20px) rotate(30deg) scale(.3);
  }

  100% {
    transform: translate(0) rotate(0) scale(1);
    opacity: 1;
  }
}
@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i');
/* ABOUT US Animation */
.about-us {
  font-family: 'cursive';
  font-size: 50px;
  color: yellow;
  z-index: 1;
  opacity: 0;
  transform: translate(-150px, -50px) rotate(-180deg) scale(3);
  animation: revolveScale .4s forwards;
  animation-delay: 1s; /* Delaying to differentiate it from the other animation */
}