.card1 {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
    opacity: 0;
    transform: translateY(20px);
  }
  
  .card.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  .card-value {
    font-size: 2em;
    font-weight: bold;
  }
  
  .card-label {
    font-size: 1em;
    color: #777;
  }
  .dashboard-container {
    padding: 20px;
    background: #f9f9f9;
    min-height: 100vh;
  }
  
  .dashboard-heading {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 40px;
    font-family: 'Arial', sans-serif;
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .card1 {
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 1s ease-out, opacity 0.5s ease-out;
    opacity: 0;
    transform: translateY(20px);
  }
  
  .card1.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  .card-value {
    font-size: 2.5em;
    font-weight: bold;
    color: #2c3e50;
  }
  
  .card-label {
    font-size: 1.2em;
    color: #7f8c8d;
  }
  .dashboard-container {
    padding: 60px 20px;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 50px;
  }
  .dashboard-container1 {
    padding: 0px 20px;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    min-height: 60vh;
    
    border-radius: 50px;
    background-color:linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  }
  
  .dashboard-heading {
    text-align: center;
    font-size: 3em;
    margin-bottom: 40px;
    font-family: 'Arial', sans-serif;
    color: #34495e;
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
    