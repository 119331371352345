.first-section-suc {
  background-image: url("https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/1.jpg");
}
.image-section-suc {
  height: 65vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: end;
  color: white;
  font-size: 2rem;
  padding-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}
.grid-item-suc video {
  width: 100%;
  height: auto;
  border-radius: 20px;
}
.grid-container-suc {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 30px;
  margin-top: 50px;
  margin-bottom: 100px;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .grid-container-suc {
      grid-template-columns: 1fr;
      padding: 20px;
      gap: 20px;
  }
  .image-section-suc{
    height: 35vh;
  }
}
.grid-item-suc {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
