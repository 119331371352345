.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px;
  transition: background-color 0.3s, transform 0.3s;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(199, 231, 235, 0.274);
 
}

.navbar-container {
  display: flex;
  justify-content: space-around;
  height: 30px;
  width: 100%;
}

.navbar.visible {
  transform: translateY(0);
}

.navbar.hidden {
  transform: translateY(-100%);
}

.navbar-scrolled {
  background-color: white;
  color: black;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 2px 40px;
  z-index: 10;

}

.navbar .nav-link {
  color: white;
  transition: color 0.3s;
  font-weight: bold; /* Adding font weight */
   /* Adding font style */
}

.navbar.navbar-scrolled .nav-link {
  color: black;
}

.nav-item .nav-link {
  cursor: pointer;
}

.nav-item .nav-link:hover {
  color: pink;
}

.programs-dropdown {
  position: absolute;
  display: none;
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 1000;
  border-radius: 20px;
  margin-bottom: 10px;
  text-decoration: none;
}

.nav-programs:hover .programs-dropdown {
  display: block;
  color: pink;
}

.program-option {
  margin: 5px 0;
  cursor: pointer;
}

.program-option:hover {
  color: #d738eb;
}

.navbar-toggler {
  color: black;
}

.mobile-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: black;
}

.mobile-toggle i {
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 10px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }

  .nav-menu .nav-link {
    color: black;
    margin-bottom: 10px;
  }

  .nav-menu .nav-link:last-child {
    margin-bottom: 0;
  }
  .none{
    display: none;
  }
  .donate{
    display: none;
  }
}

.donate {
  position: relative;
  width: 130px;
  height: 50px;
  background-color: white;
  border-radius: 30px;
  color: black;
  padding-bottom: 5px;
  margin-left: 40px;
  text-align: center;
  font-size: 20px;
  z-index: 7;
}

.navbar-scrolled .donate {
  background-color: rgb(211, 190, 197);
}

.donate::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top left, rgba(255,0,150,1), rgba(0,204,255,1));
  transform: scaleX(0);
  transform-origin: center left right;
  transition: transform 0.5s ease-in-out;
  z-index: -1;
  border-radius: 20px;
}

.donate:hover::before {
  transform: scaleX(1);
  transform-origin: center left right;
  border-radius: 20px;
}

.donate:hover {
  color: black; /* Ensuring text color remains consistent during hover */
}
.nav-item {
  margin: 0 10px;
  font-size: 19px;
  font-weight: bold;
  cursor: pointer;

}
.navbar-divider {
  margin: 0;
  border: 0;
  border-top: 1px solid #ddd; /* Adjust the color as needed */
}
