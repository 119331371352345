.container {
    position: relative; 
    width: 100%; 
    height: 600px; 
    margin: 0 auto; 
    overflow: hidden; 
    margin-top: 100px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.6);
    transition: background-color 0.3s ease;
  }
 
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    z-index: -1;
  
  }
  
  .content {
    position: relative;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    color: white; 
    padding: 20px; 
    z-index: 1; 
    padding-top:300PX ;
  }
  
  .content h2 {
    font-size: 30px; 
    margin-bottom: 20px;
    font-family: Arial, Helvetica, sans-serif
  }
  
  .donate-button {
    background-color: #007bff;
    color: white; 
    padding: 20px 30px; 
    border: none;
    border-radius: 20px; 
    cursor: pointer;
    font-size: 20px;
  }
  .donate-button:hover{
    background-color: pink;
    transition: background-color 0.3s ease;
    color: black;
  }
  .console-container {
    font-family: Khula;
    font-size: 3em;
    text-align: center;
    height: 100px;
    width: 600px;
    display: block;
    position: absolute;
    color: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  
  .console-underscore {
    display: inline-block;
    position: relative;
    top: -0.14em;
    left: 10px;
  }
  @media (max-width: 768px) {
    .container {
        height: 400px; /* Smaller height for mobile */
        margin-top: 50px; /* Less margin for mobile */
        border-radius: 25px; /* Smaller border radius for mobile */
    }

    .content {
        padding-top: 150px; /* Less padding for mobile */
    }

    .console-container {
        font-size: 1.5em; /* Even smaller font size for smaller screens */
        width: 100%; /* Full width for smaller screens */
    }

    .donate-button {
        padding: 10px 20px; /* Adjusted padding for smaller buttons on mobile */
        font-size: 16px; /* Smaller font size for mobile */
    }
}