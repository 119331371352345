.first-section-ani {
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwiuzZOzA3OWUcREopb5T4zYTNh8q_gOvaHg&s");
  }
  .image-section-ani {
    height: 65vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: end;
    color: white;
    font-size: 2rem;
   padding-bottom: 20px;
   background-color: rgba(0, 0, 0, 0.5);
   border-bottom-left-radius: 50px;
   border-bottom-right-radius: 50px;

  }